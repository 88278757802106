import React, { useState } from "react"
import {
  makeStyles, Avatar, Typography, Link, Tooltip, IconButton, List, ListItem,
  ListItemText, Divider, Card, CardMedia, CardContent, Grid, Button, Modal
} from "@material-ui/core"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NftIcon from "./NftIcon";
import { logger } from '../config/pino';
import { string } from "prop-types";
import { useSnackbar } from "notistack"
import InfoIcon from '@material-ui/icons/Info';
import SendIcon from '@material-ui/icons/Send';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Skeleton } from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardContent: {
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  cardText: {
    // display: 'flex',
    textAlign: 'center',
  },
  avatar: {
    margin: 0,
    backgroundColor: '#000000',
    color: '#ffffff',
    alignSelf: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  mediaContainer: {
    position: 'relative'
  },
  media: {
    // maxWidth: `500px`,
    width: `100%`,
    //maxHeight: '350px',
    // height: '25vw',
    objectFit: "contain"
  },
  playButton: {
    position: 'absolute',
    top: '36%',
    left: '36%',
    color: 'white',
    fontSize: '6rem'
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    backgroundColor: '#000000',
  },
  // used for padding around loading bar/spinner
  loading: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    height: '5rem',
    width: '100%',
  },
  loadingText: {
    display: 'inline-block',
    width: '3rem',
  },
  cardRow: {
    display: 'flex',
    flexdirection: 'row',
    justifyContent: 'space-around',
  },
  nftTitle: {
    textAlign: 'center',
    margin: 'auto',
    'margin-left': '2%',
  },
  nftDescription: {
    fontSize: '0.8125rem',
    'margin': 'auto',
    'margin-top': '1rem',
    'margin-bottom': '0rem',
    width: '100%'
  },
  nftDescriptionBottom: {
    fontSize: '0.7rem',
    margin: 'auto',
    textAign: 'center',
    display: 'block',
    color: 'rgba(0, 0, 0, 0.87) !important',
    // backgroundColor: 'blue'
  },
  nftVideoModal: { display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' },
  nftVideo: {
    maxWidth: '100%'
  },
  chipList: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.2),
    'padding-left': 0,
    'padding-right': 0,
    margin: 0,
    // '& > *': {
    //   margin: theme.spacing(0.2),
    // },
  },
  chipItem: {
    // margin: theme.spacing(0.2),
    fontSize: '0.8125rem',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  chipIcon: {
    // margin: theme.spacing(0.2),
    fontSize: '0.625rem',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  infoTooltip: {
    fontSize: '1rem !important',
    padding: '0',
    margin: 0
  },
  infoListRow: {
    display: 'flex',
    'flex-direction': 'row',
    width: '100%'
  },
  infoList: {
    padding: '1.5rem 0.5rem',
    width: '100%',
  },
  infoListItem: {
    //padding: 0,
    'padding-left': '2%',
    margin: 0,
  },
  bottomTooltip: {
    height: '1rem',
    width: '1rem',
    fontSize: '1rem !important',
    display: 'block',
    margin: 'auto',
    padding: 0,
  },
  bottomButtonIcons: {
    fontSize: '1.3rem !important',
    padding: '0',
    color: 'rgba(0, 0, 0, 0.54) !important'
  },
  bottomLink: {
    color: 'inherit',
    textDecoration: 'none !important'
  },
  bottomButtons: {
    // height: '5rem',
    // width: '5rem',
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
    wordWrap: 'normal',
    textTransform: 'none',
    borderRadius: '10rem',
    border: 0,
    color: theme.palette.text.primary,
    lineHeight: '2',
  }
}));

export function RugbyNftCard({

  nftAddress = "",
  nftTokenId = "",
  isLoading = true,
  isError = false,
  errorMsg = "",
  metadata = {
    animation_url: string,
    attributes: [{ trait_type: '', value: '', display_type: '' }],
    title: string,
    description: string,
    external_url: string,
    image: string,
    name: string,
    address: string,
    tokenId: string
  }
}) {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Queue error messages to the snackbar
  const { enqueueSnackbar } = useSnackbar();
  if (errorMsg.length > 0) {
    console.log(`NFT Card error: ${errorMsg}`)
    enqueueSnackbar(errorMsg, {
      variant: 'error',
      autoHideDuration: 3000,
    });
  }


  let game: string = '...'
  let year: string = '...'
  let video: string | undefined = !!metadata.animation_url ? String(metadata.animation_url) : undefined


  // Parse attributes array
  if (!!metadata.attributes) {

    // Parse client
    const gameAttribute = metadata.attributes.find((attributeObject) => (!!attributeObject.trait_type && attributeObject.trait_type === "Game"))
    if (!!gameAttribute) {
      logger.debug(`Client returned: ${gameAttribute.value}`)
      game = gameAttribute.value
    }

    // Parse status
    const yearAttribute = metadata.attributes.find((attributeObject) => (!!attributeObject.trait_type && attributeObject.trait_type === "Year"))
    if (!!yearAttribute) {
      logger.debug(`Status returned: ${yearAttribute.value}`)
      year = yearAttribute.value
    }
  }



  // logger.debug(`Rendering Nft Card.`)
  //logger.info(`Metadata object supplied to NftCard.tsx: ${JSON.stringify(metadata)}`)

  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.card}>

      {isLoading ? <Skeleton variant="rect" className={classes.loading} /> :
        <div className={classes.mediaContainer} onClick={handleOpen}><CardMedia
          className={classes.media}
          component="img"
          image={!!metadata.image ? metadata.image.toString() : '/SocialPreviewKS.png'}
          title="NFT Image"
        />
          <PlayCircleFilledWhiteIcon className={classes.playButton} fontSize="large" />
        </div>
      }
      <CardContent className={classes.cardContent}>

        <div className={classes.cardRow}>
          <Avatar className={classes.avatar}>
            {!isError && (<NftIcon />)}
            {!!isError && (<ErrorOutlineIcon />)}
          </Avatar>

          <Typography component="h1" variant="h5" className={classes.nftTitle} gutterBottom>
            <span style={{ fontWeight: 'bold' }}>
              {
                !isLoading && !!metadata.name ? `${metadata.name}` : <Skeleton variant="text" className={classes.loadingText} />
              }
            </span>

          </Typography>
        </div>

        <div className={classes.cardRow}>
          <Typography component="p" className={classes.nftDescription} gutterBottom>
            {
              !isLoading && !!metadata.description ? metadata.description : <Skeleton variant="text" className={classes.loadingText} />
            }
          </Typography>
        </div>

        <List className={classes.infoList}>
          <ListItem className={classes.infoListItem}>
            <div className={classes.infoListRow}>
              <ListItemText>
                <span style={{ fontSize: '0.8125rem', fontWeight: 'bold' }}>
                  Game: <span style={{ fontWeight: 'normal' }}>{
                    isLoading ? <Skeleton variant="text" className={classes.loadingText} /> : game
                  }</span>
                </span>
              </ListItemText>
              <Tooltip title="Moment Game" className={classes.infoTooltip}>
                <IconButton>
                  <InfoIcon className={classes.infoTooltip} />
                </IconButton>
              </Tooltip>
            </div>
          </ListItem>
          <Divider />
          <ListItem className={classes.infoListItem}>
            <div className={classes.infoListRow}>
              <ListItemText>
                <span style={{ fontSize: '0.8125rem', fontWeight: 'bold' }}>
                  Year: <span style={{ fontWeight: 'normal' }}>
                    {
                      isLoading ? <Skeleton variant="text" className={classes.loadingText} /> : year
                    }
                  </span>
                </span>
              </ListItemText>
              <Tooltip title="Moment Year" className={classes.infoTooltip}>
                <IconButton>
                  <InfoIcon className={classes.infoTooltip} />
                </IconButton>
              </Tooltip>
            </div>
          </ListItem>

        </List>


        <Grid container spacing={2} className={classes.cardRow}>

          <Grid item>
            <Link
              href={`https://testnets.opensea.io/assets/${nftAddress}/${nftTokenId}`}
              target="_blank"
              rel="noopener noreferrer"
              component="a"
              className={classes.bottomLink}
            >
              <Button className={classes.bottomButtons} variant="outlined">
                <ShoppingCartIcon className={classes.bottomButtonIcons} />
                <Typography component="p" className={classes.nftDescriptionBottom} >
                  Bid on OpenSea
                    </Typography>
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Link
              href={`/`}
              rel="noopener noreferrer"
              component="a"
              className={classes.bottomLink}
            >
              <Button className={classes.bottomButtons} variant="outlined">
                <SendIcon className={classes.bottomButtonIcons} />
                <Typography component="p" className={classes.nftDescriptionBottom} >
                  Share
                    </Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>

        {/* Modal for playing video files when `animation_url` is present on an NFT. */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="video"
          aria-describedby="video nft"
          className={classes.nftVideoModal}
        >
          <video className={classes.nftVideo} controls><source src={video} type="video/mp4" />Content could not be rendered</video>
        </Modal>

      </CardContent>

    </Card>
  )
}
